import React from "react"
import { Link } from "gatsby"

import SiteLayout from "../components/layout/site"

export default ({ data }) => (
  <SiteLayout title="Page not found | Developer Portal">
    <div className="container">
      <div className="row">
        <div
          classname="col"
          style={{ paddingTop: "10rem", paddingBottom: "10rem" }}
        >
          <h1 className="display-4 text-primary">Page not found</h1>
          <p className="lead">
            Oops! The page you are looking for has been removed or relocated.
          </p>
          <Link to="/" className="btn btn-link" style={{ marginTop: "1rem" }}>
            Go back
          </Link>
        </div>
      </div>
    </div>
  </SiteLayout>
)
